import pima from '../pima-api'
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useRecoilState} from "recoil";
import {selectedItemsState} from "../atoms/atoms";
import _, { cloneDeep } from "lodash"
import Item from "../components/Item";
import BackLinks from "../components/BackLinks";
import {Field, Formik} from "formik";
import {BlockButton, Hr} from "../components/styledComponents";
import Breadcrumbs from "../components/Breadcrumbs";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

export default ()=>{
  let navigate = useNavigate()

  let {index} = useParams()
  index = parseInt(index)

  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)

  const item = selectedItems[index - 1]

  useEffect(()=> {
    if(!selectedItems.length) {
      return navigate('/order-history')
    }
  }, [selectedItems, index])

  const {isLoading: loadingExchangeOptions, data: exchangeOptions} =
    useQuery(['exchange-options', {itemId: item?.id}], ()=> pima.getExchangeOptions(item?.id))

  const colors = {};
  let sizes = [];

  exchangeOptions?.sort((a,b)=> a.size_position - b.size_position)?.forEach(opt => {
    colors[opt.color_id] = opt.color_code

    sizes.push(opt.size)
  })

  sizes = _.uniq(sizes)

  if(!item) { return <></> }

  return (
    <div className={appStyles.appwr}>

      <BackLinks step_name={'Return Type'} />


      <div className={`${styles.step} ${styles.exchange}`}>

        <h1 className='view_title'>Exchange Product</h1>

        <Breadcrumbs current={'exchange'} isExchange={true} />

        <h2 style={{margin:'2rem 0', fontSize: '1.4rem'}}>Returning</h2>
        <Item item={item} isReturnable={true} isStatic={true} />

        {loadingExchangeOptions && 'Loading...'}

        {exchangeOptions && (
          <Formik
            initialValues={{
              color_id: item.exchange_color_id || exchangeOptions[0].color_id?.toString(),
              size: item.exchange_size || exchangeOptions[0].size
          }}
            onSubmit={(values, {resetForm}) => {
              let clonedItems = cloneDeep(selectedItems)

              clonedItems.forEach(cItem => {
                if(cItem.id === item.id) {
                  cItem.exchange_color = colors[values.color_id]
                  cItem.exchange_color_id = values.color_id
                  cItem.return_size = values.size
                  cItem.outItem = exchangeOptions.find(opt => opt.size === values.size && opt.color_id === parseInt(values.color_id))
                }
              })

              setSelectedItems(clonedItems)

              resetForm()

              if(selectedItems.length > index) {
                navigate(`/select-reasons/${index + 1}`)
              } else {
                navigate(`/confirm`)
              }
            }}
          >
            {({values, submitForm})=> {

              const outItem = exchangeOptions.find(opt => opt.size === values.size && opt.color_id === parseInt(values.color_id))

              return (
                <>
                  <h2 style={{margin:'2rem 0 0', fontSize: '1.4rem'}}>Getting</h2>

                  <div className={styles.getting}>
                    <Item item={outItem} style={{marginBottom:'2em'}} isReturnable={true} isStatic={true} />

                    <div className={styles.attributes}>
                      <div className={styles.color_title} style={{marginTop:'2rem'}}>
                        <h3 className={styles.title}>Select a Color: </h3>
                        <p style={{marginBottom:'1em', textTransform: 'capitalize'}}>{outItem?.color}</p>
                      </div>

                      <div className='swatches' role="group" aria-labelledby="color">
                        {Object.keys(colors).map(colorId => {
                          const isSelected = parseInt(values.color_id) === colorId;
                          const whites = ['#fff','#ffffff','#fafafa']
                          const isWhite = whites.includes(colors[colorId])

                          return (
                            <label key={colorId} id={`color-${colorId}`}>
                              <Field type={'radio'} name={'color_id'} value={colorId} style={{opacity: 0, position: 'absolute'}}/>
                              <div
                                className={`${styles.nswatch} ${isSelected ? styles['current'] : ''} ${isWhite ? styles['is_white'] : ''}`}
                                style={{
                                  backgroundColor: colors[colorId],
                                }}
                              />
                            </label>
                          );
                        })}
                      </div>

                      <Hr />

                      <h3 className={styles.title} style={{marginBottom: '1rem'}}>Select a Size: </h3>

                      <div className={styles.qsizes} role="group" aria-labelledby="size">
                        {sizes.map(size => {
                          const option = exchangeOptions.find(opt => opt.size === size && opt.color_id === parseInt(values.color_id))
                          const isSelected = values.size === size;

                          return (
                            <label key={size}>
                              <Field type={'radio'} name={'size'} value={size} disabled={!option?.in_stock} style={{opacity: 0, position:'absolute'}}/>
                              <div
                                className={`${styles.option} ${isSelected ? styles['active'] : ''}`}
                                disabled={option?.in_stock ? undefined : 'disabled'}
                              >
                              {size}
                            </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='cta_wr'>
                    <BlockButton type='submit' disabled={!outItem?.in_stock} onClick={submitForm}>Next Step</BlockButton>
                  </div>
                </>
              );
            }}

          </Formik>
        )}

        <div className='cta_wr'>
          <a href={'#'} onClick={() => navigate(-1)}>Back</a>
        </div>
      </div>
    </div>
  )
}