import {Link, useNavigate, useLocation} from "react-router-dom";
import React, {useState, useEffect, useRef} from "react"
import Logo from "./icons/logo";
import IconSearch from './icons/search'
import IconCart from './icons/cart'
import IconMenu from './icons/menu'
import IconClose from './icons/close'
import IconChevRight from './icons/chevrons'
import IconAccount from './icons/account'
import HeaderSubmenu from './HeadSubmenu'

import styles from "../scss/BmMobile.module.scss"


const MobileMenu = ({menuData, setShowMobileMenu, isMobile}) => {

  const [gender, setGender] = useState('both')

  const headers = menuData.headers.data
  const journals = menuData.journals.nodes
  const isTouches = !!document.querySelector('[is-t]')
  const isLogin = false

  const bgRef = useRef(null)
  const menuRef = useRef(null)
  const backRef = useRef(null)
  const contentRef = useRef(null)
  const bottomRef = useRef(null)

  const topMenu = headers.find( x => x.name === 'top' )
  const subMenus = headers.filter( x => x.name === 'new men' || x.name === 'new women' )
  const journal_sub = journals.find(x => x.handle === 'head-journal')
  if (journal_sub) {
    journal_sub['name'] = 'journal'
    subMenus.push(journal_sub)
  }

  const [menu, setMenu] = useState(false)

  const specials = ['Highlights','Promo','Journal','Collaborations','Featured']
  const is_stag = process.env.GATSBY_CURR_ENV === 'development'
  const auth_url = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'

  const setFreeze = () => {

  }

  const handleClick = ( gender ) => {
    let curr_menu = false
    if (gender !== 'the journal') {
      sessionStorage.removeItem('mobjorn')
      curr_menu = subMenus.find(x => x.name === `new ${gender}`)?.menu
    } else {
      sessionStorage.setItem('mobjorn', 'true')
      curr_menu = subMenus.find(x => x.handle === 'head-journal')
    }
    setGender(gender)
    setMenu(curr_menu)
  }

  const top_journal = topMenu.menu.find(x => x.name === 'The Journal')

  const has_collabsfeats = () => {
    const collabsfeats = menu.filter(x => x.name === 'Collaborations' || x.name === 'Featured') 
    if (collabsfeats.length < 1) return false

    return collabsfeats
  }

  const has_promo = () => {
    return menu.find(x => x.name === 'Promo')
  }

  const journ_stories = (data) => {
    return data.filter(x => x.name === 'stories')
  }

  const journ_other = (data) => {
    return data.filter(x => x.name !== 'stories')
  }

  const handleClose = () => {
    const bg_el = bgRef.current
    bg_el.setAttribute('aria-hidden', 'true')

    const menu_el = menuRef.current
    setFreeze(false)
    
    menu_el.classList.add(styles.off)
    menu_el.setAttribute('aria-hidden', 'true')

    setTimeout(() => {
      setShowMobileMenu(false)
    }, 350)
  }


  const openAnimate = (direct) => {
    let delay = 30

    if (menu) {
      setTimeout(() => {
        const back_el = backRef.current
        if (back_el) back_el.style.opacity = 1
        document.querySelectorAll(`.${styles.content} a`).forEach(x => {
          setTimeout(() => {
            x.style.opacity = direct
          }, delay)
          delay += 30
        })
      }, 150)
      return
    }
    const bottom_el = bottomRef.current
    if (!bottom_el) return
    bottom_el.style.opacity = direct
    bottom_el.style.transform = 'translate(0,0)'
    setTimeout(() => {
      document.querySelectorAll(`.${styles.bottom} .${styles.top_link}`).forEach(x => {
        setTimeout(() => {
          x.style.opacity = direct
        }, delay)
        delay += 30
      })
      document.querySelectorAll(`.${styles.bottom} .${styles.acctlink}`).forEach(x => {
        setTimeout(() => {
          x.style.opacity = direct
        }, delay)
        delay += 30
      })
    }, 150)
  }

  const subMenuIn = () => {
    const content_el = contentRef.current
    const back_el = backRef.current
    if (!back_el) return
    if (content_el) {
      content_el.style.transform = 'translate(0,0)'
      back_el.style.opacity = 1
      back_el.style.transform = 'translate(0,0)'
      setTimeout(() => {
        content_el.classList.add(`${styles.contentscroll}`)
      }, 350)
    }
  }

  const subMenuOut = () => {
    const content_el = contentRef.current
    const back_el = backRef.current
    if (content_el) {
      content_el.style.transform = ''
      back_el.style.opacity = 0
      back_el.style.transform = ''
      setTimeout(() => {
        content_el.classList.remove(`${styles.contentscroll}`)
      }, 350)
    }
  }

  const bmurl = (url) => {
    if (url.includes('https:') && !url.includes('buckmason.com')) return url
    return url.includes('www.buckmason.com') ? url : `https://www.buckmason.com${url}`
  }

  const handleBack = () => {
    subMenuOut()
    setTimeout(() => {
      openAnimate(0)
    }, 20)
    setTimeout(() => {
      setMenu(false)
    }, 150)
    setTimeout(() => {
      openAnimate(1)
      setMenu(false)
    }, 210)
  }
  
  useEffect(() => {
    setTimeout(() => {
      subMenuIn()
    }, 150)
    setTimeout(() => {
      openAnimate(1)
    }, 200)
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!bgRef?.current) return
    const bg_el = bgRef.current
    bg_el.setAttribute('aria-hidden', 'false')
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgRef])

  useEffect(() => {
    const menu_el = menuRef.current
    setFreeze(true)
    setTimeout(() => {
      if (menu_el) menu_el.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [setFreeze])

  useEffect(() => {
    if (!!menu) {
      setTimeout(() => {
        subMenuIn()
      }, 150)
      setTimeout(() => {
        openAnimate(1)
      }, 200)
      return
    }
    setTimeout(() => {openAnimate(1)}, 50)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])


  return (
    <>
      <button 
        className={[styles.aria_btn, styles.bg].join(' ')} 
        aria-hidden="true" 
        ref={bgRef} 
        onClick={(e)=> {handleClose()}}
        aria-label={'Close menu'} 
      />
      <nav 
        ref={menuRef}
        className={styles.menu}
        aria-label="Main menu"
        role="dialog" 
        aria-modal="true"
        aria-hidden="true"
      >
        <button 
          className={[styles.aria_btn, styles.close].join(' ')}
          aria-label="Close menu" 
          onClick={(e)=> {handleClose()}}
        >
          <IconClose icon_classes={''} />
        </button>

        <Link to={"https://www.buckmason.com/"} className={styles.logo} aria-label="Go to homepage">
          <Logo 
            logo_className={''} 
            onClick={(e)=> {handleClose()}}
          />
        </Link>

        {menu ? (
          <>
            <button 
              ref={backRef}
              className={[styles.aria_btn, styles.back, styles.cta_link].join(' ')}
              onClick={(e)=>{ handleBack(); sessionStorage.removeItem('mobjorn')}}
            >
              <span>BACK</span>
            </button>
            <div className={styles.content} ref={contentRef}>
              <div 
                className={[styles.sub, styles.panel].join(' ')}
                aria-hidden="false" 
                aria-expanded="true" 
              >
                {menu.name === 'journal' ? (
                  <a 
                    href={`https://www.buckmason.com/blogs/journal`}
                    className={[styles.top_link].join(' ')}
                    onClick={(e)=> handleClose()}
                  >
                    Journal
                  </a>
                ) : (
                  <a 
                    href={`https://www.buckmason.com/?g=${gender}`}
                    className={[styles.top_link].join(' ')}
                    onClick={(e)=> handleClose()}
                  >
                    {gender}
                  </a>
                )}
                {(menu && menu.name !== 'journal') && (
                  <>
                    {menu.map( (item, index) => {

                      return (
                        <React.Fragment key={`subcol-${gender}${index}`}>

                          {item.name === 'Highlights' && (
                            <ul 
                              className={[styles.group, styles.features].join(' ')}
                              aria-hidden={'false'}
                            > 
                              {item.group_links.map( (link, index) => 
                                <li key={`mobsublink-${gender}${index}`}>
                                  <a
                                    className={styles.link}
                                    href={bmurl(link.url)} 
                                    onClick={(e)=> {
                                      handleClose()
                                    }}
                                  >
                                    {link.name}
                                  </a>
                                </li>
                              )}
                            </ul>
                          )}
                          {(!specials.includes(item.name)) && (
                            <ul 
                              className={styles.group}
                              aria-hidden={'false'}
                            > 
                              {item.group_links.map( (link,index) =>
                                <li key={`${item.name}${index}`}>
                                  <a
                                    className={styles.link}
                                    href={bmurl(link.url)} 
                                    onClick={(e)=> {
                                      handleClose();
                                    }}
                                  >
                                    {link.name}
                                  </a>
                                </li>
                              )}
                            </ul>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </>
                )}
                {(menu && menu.name !== 'journal' && has_collabsfeats()) && (
                  <div className={[styles.group, styles.journ_group].join(' ')}>
                    {has_collabsfeats().map(group => 
                      <ul 
                        key={`${gender}${group.name}`}
                        className={styles.group}
                        aria-hidden={'false'}
                      > 
                        <li>
                          <div className={styles.title}>
                            {group.name}
                          </div>
                        </li>
                        {group.group_links.map( (link,index) => {

                          return (
                            <li key={`${group.name}${index}`}>
                              <a
                                className={styles.link}
                                href={bmurl(link.url)} 
                                onClick={(e)=> {
                                  handleClose();
                                }}
                              >
                                {link.name}
                              </a>
                            </li>

                        )})}
                      </ul>
                    )}
                  </div>
                )}
                {(menu && menu.name !== 'journal' && has_promo()) && (
                  <div className={styles.promos}>
                    {has_promo().group_links.map( (link, index) => 
                      <div key={`promo-${gender}${index}`} className={styles.promo_item}>
                        <a 
                          href={bmurl(link.url)}
                          onClick={(e)=> {
                            handleClose();
                          }}
                        >
                          <img 
                            className={styles.promo_img} src={link.image_url}
                            alt="" 
                            loading="lazy" 
                            width="300" 
                            height="400"
                          />
                        </a>
                        <p>
                          <a 
                            className={styles.link}
                            href={bmurl(link.url)}
                            onClick={(e)=> {
                              handleClose();
                            }}
                          >
                            {link.name}
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {(menu && menu.name === 'journal') && (
                  <>
                    {journ_stories(menu.data).length > 0 && (
                      <div className={[styles.group, styles.journ_group].join(' ')}>
                        <div className={styles.title}>STORIES</div>
                        {journ_stories(menu.data).map( (group) => 
                          <ul key={`${gender}${group.name}${group.gender}`} className={styles.sub_group}>
                            <li>
                              <div className={[styles.title, styles.subtitle].join(' ')}>
                                {group.gender === 'both' ? 'Buck Mason' : group.gender}
                              </div>
                            </li>
                            {group.menu.map( (link) => 
                              <li key={`sublink-${gender}${link.handle}`}>
                                <a 
                                  className={styles.link}
                                  href={bmurl(`/blogs/journal/${link.handle}`)} 
                                  onClick={(e)=> handleClose()}
                                >
                                  {link.title}
                                </a>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    )}
                    {journ_other(menu.data).length > 0 && (
                      <>
                        {journ_other(menu.data).map( (group,index) => 
                          <div key={`other${gender}${group.name}`} className={[styles.group, styles.journ_group].join(' ')}>
                            <div className={styles.title}>{group.name}</div>
                            <ul className={styles.sub_group}>
                              {group.menu.slice(0,5).map( (link) => 
                                <li key={`sublink-${gender}${link.handle}`}>
                                  <a 
                                    className={styles.link}
                                    href={bmurl(`/blogs/journal/${link.handle}`)} 
                                    onClick={(e)=> handleClose()}
                                  >
                                    {link.title}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                

                <div className={styles.bottom} style={{marginTop: '3em'}}>
                  <a 
                    href={bmurl('/pages/our-stores')} 
                    className={styles.link} 
                    onClick={(e)=> {
                      handleClose();
                    }}
                  >
                    Our Stores
                  </a>

                  <Link
                    to={'/account'}
                    className={[styles.link].join(' ')} 
                    aria-label="Your account"
                    onClick={(e)=> {
                      handleClose();
                    }}
                  >
                    Account
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.content} />
        )}

        {!menu && (
          <div className={styles.bottom} ref={bottomRef}>
            {topMenu.menu.filter(x => x.name !== 'The Journal').map( (item, index) => {
              const gender = item.name.toLowerCase()

              return(
                item.url === 'headsp_main-btn' ? (
                  <button 
                    key={`menu-${index}`}
                    className={[styles.aria_btn, styles.top_link].join(' ')}
                    onClick={(e) => {
                      handleClick(gender);
                    }}
                    mob-menu={item.name.toLowerCase()}
                  >
                    {item.name}
                    <IconChevRight />
                  </button>
                ) : (
                  <a 
                    key={`headtop-${index}`}
                    href={bmurl(item.url)} 
                    className={[styles.aria_btn, styles.top_link].join(' ')}
                    onClick={(e)=> {
                      handleClose();
                    }}
                    knit-lnk={item.url === '/pages/buck-mason-knitting-mills' ? '' : undefined}
                  >
                     {item.name}
                  </a>
                )
              )
            })}
            {top_journal && (
              <button 
                className={[styles.aria_btn, styles.top_link].join(' ')}
                onClick={(e) => {
                  handleClick(top_journal.name.toLowerCase());
                }}
              >
                {top_journal.name}
                <IconChevRight />
              </button>
            )}
            <br/>
            <a 
              href={bmurl('/pages/our-stores')} 
              className={[styles.link, styles.acctlink].join(' ')} 
              onClick={(e)=> {
                handleClose();
              }}
            >
              Our Stores
            </a>

            <Link 
              to={'/account'}
              className={[styles.link, styles.acctlink].join(' ')} 
              aria-label="Your account"
              onClick={(e)=> {
                handleClose();
              }}
            >
              Account
            </Link>
          </div>
        )}
      </nav>
    </>
  )
}


export default MobileMenu;