import {useQuery} from "react-query";
import pima from "../pima-api";
import useCustomer from "../hooks/useCustomer";
import {Formik, useFormikContext} from "formik";
import {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {selectedItemsState} from "../atoms/atoms";
import BackLinks from "../components/BackLinks";
import {
  CtaA,
  BlockButton,
  BlockField,
  Button,
  Hr,
  Label,
  RadioField
} from "../components/styledComponents";
import Breadcrumbs from "../components/Breadcrumbs";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

import {CardElement, Elements, ElementsConsumer, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

export default ({})=>{
  let navigate = useNavigate()
  const customer = useCustomer()
  const email = customer?.email;

  const selectedItems = useRecoilValue(selectedItemsState)

  const {isLoading: shippingMethodsLoading, data: shippingMethods} =
    useQuery(['shipping-methods', {customer: email}], pima.getShippingMethods)

  const [updatedEmail, setUpdatedEmail] = useState()
  const [updatingEmail, setUpdatingEmail] = useState()
  const changeEmail = ()=>{ setUpdatingEmail(true) }

  useEffect(()=>{
    document.querySelector('#address_name')?.focus()
  }, [updatingEmail])

  const [updatedAddress, setUpdatedAddress] = useState()
  const [updatingAddress, setUpdatingAddress] = useState()
  const changeAddress = ()=>{ setUpdatingAddress(true) }

  useEffect(()=>{
    document.querySelector('#address_line1')?.focus()
  }, [updatingAddress])

  const {isLoading: addressLoading, data: address} =
    useQuery(['customer-address', {customer: email}], pima.getCustomerAddress)

  const currentAddress = updatedAddress || address;
  const currentEmail = updatedEmail || email;
  const isExchange = selectedItems.filter((item) => item.outItem).length > 0 

  const needsShippingRate = useMemo(()=> selectedItems.find(item => item.outItem), [selectedItems])

  const [error, setError] = useState()

  useEffect(() => {
    if (!!currentEmail) return
    setUpdatingEmail(true)
  }, [currentEmail])

  useEffect(() => {
    if (!!currentAddress && Object.keys(currentAddress).length > 0) return
    setUpdatingAddress(true)
  }, [currentAddress])

  return (
    <div className={appStyles.appwr}>

      <BackLinks step_name={'Return Summary'} />

      <div className={styles.step}>

        <h1 className='view_title'>Shipping</h1>

        <Breadcrumbs current={'shipping'} isExchange={isExchange} />

        <div className='view-copy'>
          <p>
            {isExchange ?
              'Please confirm the delivery address and shipping service you would like to use for your exchange.'
              :
              "Please confirm your contact email address and return address below in case there are any issues with your return."
            }
          </p>
        </div>

        <Hr style={{margin: '2em 0'}}/>
        <strong>Shipping contact email address</strong>
        {updatingEmail ? (
          <>
            <Formik
              initialValues={{email:''}}
              onSubmit={(values, {resetForm}) => {
                setUpdatedEmail(values.email)
                setUpdatingEmail(false)
                resetForm()
              }}
            >
              {({submitForm, resetForm})=>(
                <form style={{maxWidth: '45em', margin: '0 auto'}}>
                  <Label style={{display: 'none'}}>Your email:</Label>
                  <BlockField type={'email'} name={'email'} autoComplete={'email'} id={'email'} placeholder={'Your Email'} style={{marginBottom:'1em'}}/>

                  <div style={{textAlign: 'right'}}>
                    <Button onClick={submitForm}>Save</Button>
                    &nbsp;
                    <Button
                      onClick={()=> {
                        resetForm()
                        setUpdatingEmail(false)
                      }}
                    >Cancel</Button>
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <p>
            {currentEmail}
            <div style={{float:'right'}}>
              <CtaA onClick={changeEmail}>Change</CtaA>
            </div>
          </p>
        )}

        <Hr style={{margin: '2em 0'}}/>

        <strong>
          {isExchange ? 'Shipping address' : 'Return address'}
        </strong>
        {addressLoading && 'Loading...'}

        {updatingAddress ? (
          <>
            <Formik
              initialValues={{}}
              onSubmit={(values, {resetForm}) => {
                setUpdatedAddress(values)
                setUpdatingAddress(false)
                resetForm()
              }}
            >
              {({submitForm, resetForm})=>(
                <form style={{maxWidth: '45em', margin: '0 auto'}}>
                  <div className={appStyles.field}>
                    <Label style={{display: 'none'}}>Name:</Label>
                    <BlockField type={'text'} name={'name'} id={'address_name'} placeholder={'Name'} required/>
                  </div>
                  <br />
                  <div className={appStyles.fieldset}>
                    <div className={appStyles.field}>
                      <Label style={{display: 'none'}}>Address Line 1:</Label>
                      <BlockField type={'text'} name={'address_line1'} placeholder={'Address Line 1'} required/>
                    </div>
                    <div className={appStyles.field}>
                      <Label style={{display: 'none'}}>Address Line 2:</Label>
                      <BlockField type={'text'} name={'address_line2'} placeholder={'Address Line 2'}/>
                    </div>
                  </div>
                  <br />
                  <div className={appStyles.fieldset}>
                    <div className={appStyles.field}>
                      <Label style={{display: 'none'}}>City:</Label>
                      <BlockField type={'text'} name={'address_city'} placeholder={'City'} required/>
                    </div>
                    <div className={appStyles.field} style={{maxWidth: '12em'}}>
                      <Label style={{display: 'none'}}>State:</Label>
                      <BlockField type={'text'} name={'address_state'} placeholder={'State'} required/>
                    </div>
                    <div className={appStyles.field} style={{maxWidth: '6em'}}>
                      <Label style={{display: 'none'}}>Zip:</Label>
                      <BlockField type={'text'} name={'address_zip'} placeholder={'Zip'} required/>
                    </div>
                  </div>
                  <div className={appStyles.fieldset}>
                    <div className={appStyles.field}>
                      <Label style={{display: 'none'}}>Country:</Label>
                      <BlockField type={'text'} name={'address_country'} placeholder={'Country'} required/>
                    </div>
                    <div className={appStyles.field}>
                      <Label style={{display: 'none'}}>Phone:</Label>
                      <BlockField type={'text'} name={'address_phone'} placeholder={'Phone'} required/>
                    </div>
                  </div>
                  <br/>
                  <br/>

                  <div style={{textAlign: 'right'}}>
                    <Button onClick={submitForm} style={{marginRight:'1em'}}>Save</Button>
                    <Button
                      onClick={()=> {
                        resetForm()
                        setUpdatingAddress(false)
                        setUpdatedAddress(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div style={{display:'flex'}}>
            {currentAddress && (
              <p>
                {currentAddress.name}
                <br/>
                {currentAddress.address_line1}, {currentAddress.address_line2}
                <br/>
                {currentAddress.address_city}, {currentAddress.address_state} {currentAddress.address_zip}, {currentAddress.address_country}
                <br/>
                {currentAddress.address_phone}
              </p>
            )}

            <div style={{marginLeft: 'auto'}}>
              <CtaA onClick={changeAddress}>Change</CtaA>
            </div>
          </div>
        )}

        <>
          {shippingMethodsLoading && 'Loading...'}

          {shippingMethods && (
            <>
              <Formik
                initialValues={{
                  shipping_rate_id: '0',
                  payment_intent_id: null
                }}
                onSubmit={(values, {setSubmitting})=>{
                  console.log(values, currentAddress, currentEmail)

                  const items = []
                  selectedItems.forEach(item => {
                    items.push({
                      order_item_id: item.id,
                      return_reason_id: item.return_reason_id,
                      return_type: item.return_type,
                      exchange_sku_id: item.outItem?.sku_id,
                    })
                  })

                  const data = {
                    ...values,
                    email: currentEmail,
                    items_attributes: items
                  }

                  if(!updatedAddress) {
                    data.customer_address_id = address.id
                  } else {
                    data.customer_address_attributes = updatedAddress
                  }

                  pima.completeReturn(data)
                    .then(data => {
                      if(data.errors) {
                        setSubmitting(false)
                        setError(data.errors)
                      } else {
                        navigate(`/completed/${data.code}`)
                      }
                    })
                    .catch((err)=> {
                      if (err?.response?.status === 422) {
                        setError({422: ['There was a problem connecting your order to your account']})
                      } else {
                        setSubmitting(false)
                        setError(data.errors)
                      }
                    })
                }}
              >
                {({values, submitForm, isSubmitting})=>{
                  const shippingMethod = useMemo(()=> shippingMethods.find(s => s.id.toString() === values.shipping_rate_id), [values.shipping_rate_id])
                  const needsPayment = shippingMethod && shippingMethod.price !== 0;

                  return (
                    <>
                      {needsShippingRate && (
                        <>
                          <h2 style={{margin:'2rem 0', fontSize: '1.2em'}}>Shipping Method</h2>

                          <div role={'group'}>
                            <label className={styles.radio}>
                              <RadioField type={'radio'} name={'shipping_rate_id'} value={'0'}/>
                              <span style={{flex: '1'}}>Ground</span>
                              <strong>Free</strong>
                            </label>
                            <Hr />

                            {shippingMethods.filter(rate => rate.price > 0).map(rate => (
                              <>
                                <label key={rate.id} className={styles.radio} >
                                  <RadioField type={'radio'} name={'shipping_rate_id'} value={rate.id.toString()}/>
                                  <span style={{flex: '1'}}>{rate.name}</span>
                                  <strong>${rate.price / 100.0}</strong>
                                </label>
                                <Hr />
                              </>
                            ))}
                          </div>
                        </>
                      )}


                      {error && (
                        <div style={{color:'red', margin: '2em 0', maxWidth: '30em'}}>
                          There's been a problem:
                          <ul>
                            {Object.keys(error).map((field,i) => (
                                <li key={i}>
                                  {field === '422' ? (
                                    <div>
                                      We're having trouble processing your return. Please reach out to our customer experience team 
                                      at <a href="mailto:help@buckmason.com" target="_blank" rel="noopener noreferrer">help@buckmason.com</a>, and 
                                      we'll get this squared away.
                                    </div>
                                  ) : (
                                    <>
                                      <strong>{field.replaceAll(/_|\./g,' ')}:</strong>
                                      &nbsp; &nbsp;
                                      {error[field].join('. ')}
                                    </>
                                  )}
                                </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <br/>

                      {needsPayment ?
                        <ShippingPayment shippingMethodId={shippingMethod.id}/> :
                        <div className='cta_wr'><BlockButton onClick={submitForm} disabled={(updatingEmail || updatingAddress) || isSubmitting}>Confirm Shipping</BlockButton></div>
                      }
                    </>
                  )
                }}
              </Formik>
            </>
          )}
        </>
      </div>
    </div>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ShippingPayment = ({shippingMethodId})=>{
  const {isLoading, data: paymentToken} =
    useQuery(['secret-token', {shippingMethodId: shippingMethodId}],
      ()=> pima.getShippingPaymentToken(shippingMethodId))

  if(isLoading) return <>Loading...</>

  const options = {clientSecret: paymentToken?.client_secret}

  return (
    <div style={{margin:'2em 0'}}>
      <h2>Payment Info</h2>

      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm clientSecret={options.clientSecret}/>
      </Elements>
    </div>
  );
}

const CheckoutForm = ({clientSecret})=>{
  const { setFieldValue, submitForm } = useFormikContext();

  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState()
  const [error, setError] = useState()

  const submitStripe = async (event) => {
    setSubmitting(true)
    setError(false)

    event.preventDefault();

    if (!stripe || !elements) return;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setError(result.error.message)
      setSubmitting(false)
    } else {

      console.log('paid', result.paymentIntent.id)
      await setFieldValue('payment_intent_id', result.paymentIntent.id)
      setTimeout(submitForm,1)
    }
  }

  return (
    <>
      {error && (
        <div style={{color:'red', margin: '2em 0'}}>Error: {error}</div>
      )}

      <div style={{border: '1px solid black', padding: '1em', marginBottom: '2em'}}>
        <CardElement/>
      </div>
      <div className='cta_wr'>
        <BlockButton onClick={submitStripe} disabled={submitting}>Submit Return</BlockButton>
      </div>
    </>
  )
}
