import * as React from "react";
import {Link} from "react-router-dom";
import styles from "../scss/Header.module.scss"

const HeaderSubmenu = ({ panel, obj }) => {
  const gender = obj.name.replace('new ', '')
  const menu = obj.menu
  const is_open = panel === gender

  return (
    <div 
      id={`nd-panel-${gender}`} 
      className={styles['nd_head-sub']}
      aria-hidden={!is_open}
      aria-expanded={is_open}
      data-panel={`panel-${gender}`} 
      style={{visibility: `${is_open ? 'visible' : 'hidden'}`, position: `${is_open ? 'relative' : 'absolute'}`}}
    > 
      <div className={`${styles['nd_head-content']} ${styles.panel}`}>
        {menu.filter(x => x.name !== 'Featured').map( (item, index) => {

          let feats = null
          if (item.name === 'Collaborations') {
            feats = menu.find(x => x.name === 'Featured')
          }

          return(
            item.name === 'Promo' ? (
              <div key={`subcol-${gender}${index}`} className={styles.promos}>

                {item.group_links.map( (link, index) => 
                  <div key={`promo-${gender}${index}`} className={styles.promo_item}>
                    <a href={`https://www.buckmason.com${item.group_links[0].url}`}>
                      <img 
                        className={styles.promo_img} src={link.image_url}
                        alt="" 
                        loading="lazy" 
                        width="300" 
                        height="400"
                      />
                    </a>
                    <p>
                      <a href={`https://www.buckmason.com${item.group_links[0].url}`}>
                        {link.name}
                      </a>
                    </p>
                  </div>
                )}
              </div>
            ) : item.name === 'Collaborations' ? (
              <div key={`subcol-${gender}${index}`} className={styles.group_wrap}>
                <ul className={styles.sub_group}>
                  <li>
                    {item.name}
                  </li>
                  {item?.group_links?.map( (link, index) =>
                    <li key={`sublink-${gender}${index}`}>
                      <a 
                        className={styles['nd_head-link']}
                        href={`https://www.buckmason.com${link.url}`} 
                      >
                        {link.name}
                      </a>
                    </li>
                  )}
                </ul>
                {feats && (
                  <ul className={styles.sub_group}>
                    <li>
                      <div className={styles.title}>
                        Featured
                      </div>
                    </li>
                    {feats?.group_links?.map( (flink, findex) =>
                      <li key={`sublink-${gender}${findex}`}>
                        <a 
                          className={styles['nd_head-link']}
                          href={`https://www.buckmason.com${flink.url}`} 
                        >
                          {flink.name}
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            ) : (
              <ul key={`subcol-${gender}${index}`} className={`${styles.sub_group} ${item.name === 'Highlights' ? styles.features : ''}`}>
                {(item.name !== '' && item.name !== 'Highlights') && (
                  <li>
                    <div className={styles.title}>
                      {item.name}
                    </div>
                  </li>
                )}
                {item?.group_links?.map( (link, index) =>
                  <li key={`sublink-${gender}${index}`}>
                    <a 
                      className={styles['nd_head-link']}
                      href={`https://www.buckmason.com${link.url}`} 
                    >
                      {link.name}
                    </a>
                  </li>
                )}
              </ul>
            )
          )
        })}
      </div>
    </div>
  )
}

export default HeaderSubmenu



