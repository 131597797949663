import {Link, useNavigate, useLocation} from "react-router-dom";
import React, {useState, useEffect, useRef} from "react"
import Logo from "./icons/logo";
import IconSearch from './icons/search'
import IconCart from './icons/cart'
import IconMenu from './icons/menu'
import IconClose from './icons/close'
import IconChevRight from './icons/chevrons'
import IconAccount from './icons/account'
import HeaderSubmenu from './HeadSubmenu'
import MobileMenu from './MobileMenu'

import styles from "../scss/Header.module.scss"

const Header = () => {
  const startloc = useLocation()
  const [changeLoc, setChangeLoc] = useState(startloc.pathname)
  const [banner, setBanner] = useState(null)
  const [topMenu, setTopMenu] = useState([])
  const [subMenus, setSubMenus] = useState([])
  const [openPanel, setOpenPanel] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [menuData, setMenuData] = useState(false)

  const [isMobile, setIsMobile] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const head_el = `.${styles.nd_head}`
  const position = {last: 0, up: false}

  let ani_pos = 0
  let change = 1

  function animate() {
    const stop = change > 0 ? ani_pos > -100 : ani_pos < 0
    if ( stop && !document.querySelector('#header')?.classList?.contains('off') ) {
      ani_pos -= change;
      document.querySelector(head_el).style.transform = `translate(0, ${ani_pos}%)`;
      requestAnimationFrame(animate);
    }
  }

  const handleScroll = () => {
    const new_pos = window.pageYOffset;
    position.up = new_pos < position.last 
    position.last = window.pageYOffset;

    if ( position.up ) {
      change = -1
      animate()
    } else {
      change = 1
      animate()
      setOpenPanel(false)
    }
  };

  const deskHover = () => {
    document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), .App`).forEach((x) => {
      x.removeEventListener('mouseenter', deskHover)
    })
    setOpenPanel(false)
  }

  const handleClick = ( status ) => {
    if ( typeof window !== "undefined" ) {
      if ( window.innerWidth < 900 ) {
        setOpenPanel(openPanel === status ? false : status)
      } else if ( status ) {
        window.location = `https://www.buckmason.com?g=${status}`
      }
    }
  }

  const handleEnter = ( status ) => {
    if ( typeof window !== "undefined" && window.innerWidth > 900 ) {
      setOpenPanel(status)
      document.querySelector(head_el).addEventListener('mouseenter', deskHover)
      document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), .App`).forEach((x) => {
        x.addEventListener('mouseenter', deskHover)
      })

    }
  }

  const getJson = async () => {
    const res = await fetch('https://www.buckmason.com/page-data/pages/api-rms/page-data.json')
    const json = await res.json()
    const menus = json.result.data.headers.data

    const top_menu = menus.find(x => x.name === 'top')
    const mens_menu = menus.find(x => x.name === 'new men')
    const womens_menu = menus.find(x => x.name === 'new women')
    const banner_menu = menus.find(x => x.name === 'HeaderBanner')

    setTopMenu(top_menu.menu)
    setSubMenus([mens_menu, womens_menu])
    setBanner(banner_menu)
    setMenuData(json.result.data)
  }

  useEffect(() => {
    const element = document.querySelector('#header');
    change = -1
    ani_pos = 0
    if (element) {
      element.style.transform = 'translate(0,0)';
    }
  });

  useEffect(() => {
    getJson()

    const mobcheck = checkMobile()
    setIsMobile(mobcheck.mobile)

  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });


  return (
    <>
      <header className={styles.nd_head} id={'header'}>
        <Link to={"#MainContent"} className="in-page-link visually-hidden skip-link">
          Skip to content
        </Link>
        <a href={"https://www.buckmason.com/pages/accessibility"} className="in-page-link visually-hidden skip-link" >
          Click to view our Accessibility Statement or contact us with accessibility-related questions
        </a>

        {banner && (
          <div className={styles.banner}>
            {banner ? (
              <>
                {banner.default}
                {banner.custom !== '' && (
                  <div className={styles.banner} dangerouslySetInnerHTML={{ __html: banner.custom }} />
                )}
              </>
            ) : (
              <>Shipping On Us Over $150 & 365-Day Returns</>
            )}
          </div>
        )}

        <nav className={[styles['nd_head-content'], styles['nd_head-top'], styles.mobhead].join(' ')} >
          <div className={styles.mobhead_sec}>
            <button 
              className={`${styles['nd_head-top_btn']} ${styles['nd_head-nodesk']}`}
              data-uipop-btn="site_menu" 
              aria-label="Open Main Menu dialog"
              onClick={(e)=>setShowMobileMenu(true)}
            >
              <IconMenu icon_classes={`head_icon icon-hamberger`}></IconMenu>
            </button>
          </div>

          <Link to="/" className={styles['head-logo_link']} aria-label="Go to homepage">
            <div className={styles['head-logo_svg']}>
              <Logo logo_class={styles['svg-logo']}></Logo>
            </div>
          </Link>

          <div className={[styles.mobhead_sec, styles.mobhead_right].join(' ')}>
            <a
              className={styles['nd_head-top_btn']}
              href={'https://www.buckmason.com/search'}
            >
              <IconSearch icon_classes={styles.nicon}></IconSearch>
            </a>
          </div>
          <div className={styles.mobhead_gend}>
            <Link 
              className={`${styles.gend_link} `} 
              to={'https://www.buckmason.com?g=men'} 
            >
              Men
            </Link>
            <Link 
              className={`${styles.gend_link} `} 
              to={'https://www.buckmason.com?g=women'} 
            >
              Women
            </Link>
          </div>
        </nav>

        <nav className={`${styles['nd_head-content']} ${styles['nd_head-top']} ${styles['deskhead']}`}>
          <div className={styles['nd_head-main']}>
            <a href={"https://www.buckmason.com/"} className={styles['head-logo_link']} aria-label="Go to homepage">
              <div className={styles['head-logo_svg']}>
                <Logo logo_class={styles['svg-logo']}></Logo>
              </div>
            </a>
            {topMenu.map( (item, index) => {
              const gender = item.name.toLowerCase()
              const is_open = openPanel === gender

              return(
                item.url === 'headsp_main-btn' ? (
                  <button 
                    className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}
                    site-menu=""
                    aria-expanded={is_open}
                    aria-controls={`panel-${gender}`} 
                    key={`headtop-${index}`} 
                    onClick={(e) => handleClick(gender) }
                    onMouseEnter={(e) => handleEnter(gender)}
                  >
                    {item.name}
                  </button>
                ) : (
                  <a 
                    href={`https://www.buckmason.com${item.url}`} 
                    className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}
                    key={`headtop-${index}`}
                  >
                     {item.name}
                  </a>
                )
              )
            })}
          </div>
          <div className={styles['nd_head-aux']}>
            <a href={"https://www.buckmason.com/pages/our-stores"} className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}>
              Our Stores
            </a>
            <Link to={"/account"} className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`} login-link="" aria-label="Your account">
              <IconAccount icon_classes={styles.nicon}></IconAccount>
            </Link>
          </div>
        </nav>
        <div 
          className={`${styles['nd_head-panels']} ${!!openPanel ? styles.open : ''}`} 
        >
          {subMenus.map( (json_obj, index) => 
            <HeaderSubmenu 
              key={`headsub-${index}`} 
              panel={openPanel} 
              obj={json_obj} 
            /> 
          )}
        </div>

        <div className={`head-sticky_menus`}></div>
        
      </header>

      {(!!menuData && showMobileMenu) && (
        <MobileMenu menuData={menuData} setShowMobileMenu={setShowMobileMenu} isMobile={isMobile} />
      )}
    </>
  )
}


const checkMobile = () => {
  if ((typeof document === "undefined") || (typeof window === "undefined")) return false

  const test_touch = sessionStorage.getItem('force-touch')
  const touches = test_touch || 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  const mobileagent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const mobile_size = window.innerWidth < 820
  const tablet_size = window.innerWidth > 819

  if (touches) document.querySelector('html').setAttribute('is-t','')

  return {touches: touches, mobile: mobile_size, tablet: touches && tablet_size, mobileagent: mobileagent}
}


export default Header;

