import * as React from "react";

const IconChevLeft = ({ icon_classes }) => {
  return (
    <svg 
      className="svg-arrow icon-arrow-left" 
      style={{enableBackground: 'new 0 0 14 27'}} 
      viewBox="0 0 14 27"
      aria-hidden="true" 
      focusable="false"
    >
      <polyline style={{stroke: 'currentColor', fill: 'transparent'}} points="12.7,2.3 1.5,13.5 12.7,24.7 "/>
    </svg>
  )
}

const IconChevRight = ({ icon_classes }) => {
  return (
    <svg 
      className={`svg-arrow icon-arrow-right ${icon_classes}`}
      style={{enableBackground: 'new 0 0 14 27'}} 
      viewBox="0 0 14 27"
      aria-hidden="true" 
      focusable="false"
    >
      <polyline style={{stroke: 'currentColor', fill: 'transparent'}} points="1.5,2.3 12.7,13.5 1.6,24.7 "/>
    </svg>
  )
}


export default IconChevRight