import pima from '../pima-api'
import {useQuery} from "react-query";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import useCustomer from "../hooks/useCustomer";
import {useRecoilState} from "recoil";
import {ordersState, selectedItemsState} from "../atoms/atoms";
import useLoadOrders from "../hooks/useLoadOrders";
import {Formik} from "formik";
import { cloneDeep } from "lodash"
import Item from "../components/Item";
import BackLinks from "../components/BackLinks";
import {BlockButton, Hr, RadioField} from "../components/styledComponents";
import Breadcrumbs from "../components/Breadcrumbs";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

export default ()=>{
  useCustomer()
  let navigate = useNavigate()

  let {index} = useParams()
  index = parseInt(index)

  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)

  const buttonTextCopy = {
    exchange: 'Continue To Select New Size Or Color',
    return: 'Next Step'
  }

  const [buttonText, setButtonText] = useState(buttonTextCopy.exchange)
  const [selectClass, setSelectClass] = useState('credit')
  const [userAction, setUserAction] = useState('return')

  const handleChange = (e) => {
    setSelectClass(e.target.value ? '' : 'unset')
    setButtonText(buttonTextCopy[userAction])
  }

  const item = selectedItems[index - 1]

  useEffect(()=> {
    if(!selectedItems.length) {
      return navigate('/order-history')
    }

    if(!item) {
      return navigate(`/confirm`)
    }
  }, [selectedItems, index])

  const {isLoading: loadingReturnReasons, data: returnReasons} = useQuery('return-reason', pima.getReturnReasons)

  const selectRef = useRef()

  if(!item) { return <></> }

  return (
    <div className={appStyles.appwr}>

      <BackLinks step_name={'Order Details'} />

      <div className={styles.step}>
        <h1 className='view_title'>Return Details</h1>

        <Breadcrumbs current={'products'} isExchange={userAction === 'exchange'} />

        <Item item={item} isReturnable={true} isStatic={true}/>

        <Formik
          initialValues={{return_type: item.return_type || 'exchange', return_reason_id: item.return_reason_id}}
          enableReinitialize
          onSubmit={(values, {resetForm, setFieldValue}) => {
            let clonedItems = cloneDeep(selectedItems)

            clonedItems.forEach(cItem => {
              if(cItem.id === item.id) {
                cItem.return_reason_id = values.return_reason_id
                cItem.return_reason = returnReasons?.find(rr => rr.id.toString() === values.return_reason_id)?.text
                cItem.return_type = values.return_type
                if(values.return_type !== 'exchange') {
                  cItem.exchange_sku_id = null
                }
              }
            })

            setSelectedItems(clonedItems)

            // selectRef.current.selected = false
            resetForm()

            if(values.return_type === 'exchange') {
              navigate(`/exchange/${index}`)
            } else if(selectedItems.length > index) {
              navigate(`/select-reasons/${index + 1}`)
            } else {
              navigate('/confirm')
            }
          }}
        >
          {({values, submitForm, setFieldValue, setClassName})=>{
            return (
              <>
                <div>
                  <h2 style={{margin: '2.5rem 0 1.5rem', fontSize: '1.1rem'}} id={'return-reason'}>
                    Select Reason for Return
                    {selectedItems.length > 1 && (
                        <> ({index} of {selectedItems.length}) </>
                    )} (Required)
                  </h2>

                  {loadingReturnReasons && 'Loading...'}

                  {returnReasons && (
                    <div role="group" aria-labelledby="return-reason">
                      {returnReasons.map(rr => (
                        <label 
                          key={rr.id} 
                          onClick={(e) => setButtonText(buttonTextCopy.exchange)}
                          className={styles.radio}
                        >
                          <RadioField 
                            type={'radio'} 
                            required
                            name={'return_reason_id'} 
                            value={rr.id.toString()} 
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('return_reason_id', e.target.value === 'false' ? null : e.target.value);
                            }}
                          />
                          {rr.text}
                        </label>
                      ))}
                    </div>
                  )}
                </div>

                

                <h2 style={{paddingTop: '2.5em', borderTop: '1px solid #DDDAD0', margin: '2rem 0 1.5rem', fontSize: '1.1rem'}} id={'return-type'}>Select Return Type</h2>

                <div role="group" aria-labelledby="return-type">
                  <label className={styles.radio} onClick={(e) => {setUserAction('exchange');setButtonText(buttonTextCopy.exchange)}}>
                    <RadioField type={'radio'} name={'return_type'} value={'exchange'} />
                    <span style={{flex: '1'}}>Exchange for a similar item</span>
                    <strong>Free</strong>
                  </label>
                  <Hr />
                  <label className={styles.radio} onClick={(e) => {setUserAction('return');setButtonText(buttonTextCopy.return)}}>
                    <RadioField type={'radio'} name={'return_type'} value={'credit'} />
                    <span style={{flex: '1'}}>Return for store credit</span>
                    <strong>Free</strong>
                  </label>
                  <Hr />
                  <label className={styles.radio} onClick={(e) => {setUserAction('return');setButtonText(buttonTextCopy.return)}}>
                    <RadioField type={'radio'} name={'return_type'} value={'original'} />
                    <span style={{flex: '1'}}>Return to original payment</span>
                    <strong>$8 total (not /item)</strong>
                  </label>
                  <Hr />
                </div>

                <div className='cta_wr'>
                  <BlockButton 
                    type='submit' 
                    disabled={!values.return_reason_id} 
                    onClick={submitForm} 
                    aria-live="polite" 
                  >
                    {buttonText}
                  </BlockButton>
                </div>
              </>
            )
          }}
        </Formik>


      </div>
    </div>
  )
}


// <BlockField
//   as={'select'}
//   name={'return_reason_id'}
//   required
//   onChange={(e) => {
//     handleChange(e);
//     setFieldValue('return_reason_id', e.target.value === 'false' ? null : e.target.value);
//   }}
//   ref={selectRef}
// >
//   <option value={false}>Choose One...</option>
//   {returnReasons.map(rr => (
//     <option key={rr.id} value={rr.id.toString()}>{rr.text}</option>
//   ))}
// </BlockField>