import useCustomer from "../hooks/useCustomer";
import {useRecoilState} from "recoil";
import {selectedItemsState} from "../atoms/atoms";
import Item from "../components/Item";
import BackLinks from "../components/BackLinks";
import {useNavigate} from "react-router-dom";
import {BlockButton, Hr} from "../components/styledComponents";
import Breadcrumbs from "../components/Breadcrumbs";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

export default ({})=>{
  let navigate = useNavigate()
  const customer = useCustomer()

  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)

  console.log(selectedItems)

  const confirm = ()=> {
    navigate('/shipping')
  }

  let totalStoreCredit = 0
  let refundSubtotal = 0
  let returnFee = 0

  selectedItems.forEach(item => {
    const amount = item.paid_price + item.tax;

    switch(item.return_type){
      case 'original':
        refundSubtotal += amount
        returnFee += 600;
        break;
      case 'credit':
        totalStoreCredit += amount;
        break;
    }
  })

  const isExchange = selectedItems.filter((item) => item.outItem).length > 0 
  const typeText = isExchange ? 'Exchange' : 'Return'
  const totalRefund = refundSubtotal - returnFee;

  return (
    <div className={appStyles.appwr}>

      <BackLinks step_name={typeText === 'Exchange' ? 'Exchange Details' : 'Return Type'} />

      <div className={styles.step}>

        <h1 className='view_title'>Confirm {typeText}</h1>

        <Breadcrumbs current={'confirmation'} isExchange={isExchange} />

        {selectedItems.map((item,i) => (
          <div key={item.id}>
            <h2 style={{marginTop:'2rem', fontWeight: '300', fontSize: '1.2rem'}}>Item {i+1} of {selectedItems.length}</h2>

            <h3 style={{margin: '2.5rem 0 1.5rem', fontSize: '1.4rem', fontWeight: 'bold'}}>Returning</h3>
            <Item item={item} isReturnable={true} isStatic={true} />

            {item.outItem && (
              <>
                <h3 style={{margin: '2.5rem 0 1.5rem', fontSize: '1.4rem', fontWeight: 'bold'}}>Getting</h3>
                <Item item={item.outItem} isReturnable={true} isStatic={true} />
              </>
            )}
          </div>
        ))}

        {totalRefund + totalStoreCredit > 0  && (
          <div style={{textAlign:'right', lineHeight:2}}>
            <Hr/>

            {refundSubtotal > 0 && (
              <>
                Refund Subtotal: ${refundSubtotal / 100.0}
                <br/>
              </>
            )}

            {returnFee > 0 && (
              <>
                Return Fee: -${returnFee / 100.0}
                <br/>
              </>
            )}

            {totalRefund > 0 && (
              <>
                <strong>Total Refund:</strong> ${totalRefund / 100.0}
                <br/>
              </>
            )}

            {totalStoreCredit > 0 && (
              <>
                <strong>Total Store Credit:</strong> ${totalStoreCredit / 100.0}
                <br/>
              </>
            )}

            <Hr/>
          </div>
        )}

        <div className='cta_wr'>
          <BlockButton onClick={confirm}>Confirm</BlockButton>
        </div>

        <div className='cta_wr'>
          <br/>
          <a href={'#'} onClick={() => navigate(-1)} className={appStyles.cta_link}>Back</a>
        </div>
      </div>
    </div>
  )
}